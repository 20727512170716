.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ModuleMainContainer{
  width: 80% !important;
  margin: auto !important;
  padding-top: 20px !important;
}

.flomecolModalContainer{
  display: flex;
  align-items: center;
  overflow: auto;
}

.flomecolModalContent{
  width: 80%;
  margin: auto;
  background-color: whitesmoke;
  padding: 30px;
  border-radius: 4px;
}

.inputTextField{
  z-index: 0;
  margin: 0px !important;
}

.modalTitle{
  width: 100%;
  text-align: center;

}

.react-date-picker{
  min-height: 56px;
  width: 100%;
  height: 100%;
}

.react-date-picker__wrapper{
  width: 100%;
  height: 100%;
  display: flex !important;
  border: thin solid #b4b5b4 !important;
  border-radius: 4px !important;
}

.date-picker-label{
  position: absolute;
  margin-top: -6px;
  margin-left: 12px;
  z-index: 10;
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.38);
  font-size: 13px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  padding: 0px 4px;
}


.tables-container{
  /*width: 90% !important;
  margin: auto !important;*/
  margin-top: 30px !important;
}

.bootstrap-table-wrapper{
  width: 100%;
  border: solid 1px #54acbb;
}

.bootstrap-table{
  width: 100%;
  border-spacing: 0px;
}

.bootstrap-table tr:hover{
  background-color: rgba(27, 45, 54, 0.51) !important;
}

.bootstrap-table-header{
  height: 35px;
  color: #000000;
  text-align: center;
  cursor: pointer;
  font-weight: bold !important;
}

.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-default {
  color: #54a7b6 !important;
  cursor: default;
  background-color: #ffffff;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  text-decoration: none;
  border: 1px solid #54a7b6;
}

.dropdown-menu {
  position: absolute;
  /*top: 100%;
  left: 0;*/
  z-index: 1000;
  display: none;
  float: left;
  min-width: 45px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
}


.show{
  display: block !important;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination>li {
  display: inline;
}

.react-bootstrap-table-page-btns-ul {
  float: right;
  margin-top: 0px;
}

.react-bootstrap-table th.sortable {
  cursor: pointer;
  /*white-space: nowrap !important;*/
}

.react-bootstrap-table{
  text-align: center;
  font-size: 12px !important;
}


@media (min-width: 992px){
  .col-md-6 {
    width: 50%;
    float: left;
    min-height: 25px;
  }
}

@media screen and (max-width: 992px){
  .react-bootstrap-table{
    font-size: 10px !important;
  }
}

@media screen and (max-width: 600px){
  .react-bootstrap-table{
    font-size: 5px !important;
  }
}



.page-item{
  color: #54a7b6 !important;
}

.page-link{
  color: #54a7b6 !important;
  cursor: default;
  background-color: #ffffff;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  text-decoration: none;
  border: 1px solid #54a7b6;
}

.dropdown-item{
  color: #54a7b6 !important;
  cursor: default;
  background-color: #ffffff;
  /*padding: 6px 12px;*/
  margin-left: -1px;
  line-height: 1.42857143;
  text-decoration: none;
  border: 1px solid #54a7b6;
}

.dropdown-item a{
  color: #54a7b6 !important;
  padding: 6px 12px;
  width: 100%;
}


.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.table-title{
  text-align: center;
}

.inputTextFieldContainer{
  padding: 5px;
}

.inputTextFieldContainerFull{
  width: 100%;
  height: 100%;
}

.react-select-container{
  padding-bottom: 10px;
}

.request-oder-list-product{
  margin: 5px;
  padding: 10px;
  border: solid 2px #336636;
  border-radius: 4px;
}

.hr-divider{
  background-color: #336636;
  height: 3px;
  width: 100%;
  margin: 5px;
  border-style: none;
  border-width: 10px;
}

.form-control{
  min-width: 120px;
}

.success-dialog-container{
  padding: 20px;
  border: solid 2px #28a745;
  text-align: center;
}

.danger-dialog-container{
  padding: 20px;
  border: solid 2px #dc3545;
  text-align: center;
}

.warning-dialog-container{
  padding: 20px;
  border: solid 2px #ffc107;
  text-align: center;
}

.pdfReaderContainer{
  /*max-width: 800px !important;*/
  overflow:hidden;
  width:100%;
  height: 100vh;
}

.pdfReaderContainerRoot{
  max-width: 90% !important;
  overflow:hidden;
  width:100%;
  height: 100vh;
}

.pdfReaderContainerObject{
  float:left;
  width:100%;
  height:auto;
}


.loadingContentContainer{
  position: relative;
  height: 100px;
  width: 100px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 50% !important;
}

.loadingLogoImg{
  width: 70%;
  height: 70%;
  position: absolute;
  padding: 15px;
  background-color: transparent;
  border-radius: 10% !important;
}

.loadingCircleImg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: #336636 !important;
  z-index: 1;
}

.loadingDialogContent{
  padding: 0 !important;
  overflow: hidden;
  overflow-y: hidden !important;
}

.loadingBackgroundPaper{
  background-color: #fff0 !important;
  box-shadow: none !important;
}

.loadingText{
  padding: 10px;
  color: #336636;
  font-weight: bold;
  text-align: center;
}

.loadingText span{
  background-color: #ffffff;
  border: solid 2px #336636;
  border-radius: 10px;
  padding: 3px;
}

.pdf-dialog-paper{
  width: 100% !important;
}

.pdf-dialog-paper iframe{
  width: 100% !important;
  height: 100vh;
}

.download-order-pdf-eye svg{
  color: #000000 !important;
}

.create-requested-order-product-select{
  font-size: 14px !important;
  color: #5d5d5d;
}

.metrics-card-container{
  padding-top: 40px;
}

.metrics-card-content{
  text-align: center;
}

.chart-bar-container{
  /*border: dashed 1px black;*/
  margin: 50px 0px 20px 0px !important;
}

.flomecol-danger-button{
  background-color: red !important;
  min-height: 55px !important;
}

.flomecol-expansion-panel{
  border: solid 2px #336636;
}

.actions-custom-cell{
  min-width: 200px !important;
}

.product-status-active{
  width: 10px;
  height: 10px;
  background-color: #336636 !important;
  border-radius: 50%;
  border: solid 1px white;
}

.product-status-inactive{
  width: 10px;
  height: 10px;
  background-color: red !important;
  border-radius: 50%;
  border: solid 1px white;
}

.product-status-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-status-change-icon{
  width: 17px !important;
  height: 17px !important;
}

.clients-search-box {
  position: relative;
  border-radius: 2px;
  margin: auto !important;
  width: 100% !important;
  border: solid 2px #336636;
  margin-top: 20px !important;
}

.clients-search-input-root {
  width: 100%;
  margin: 0px 10px;
}

.inputSelectContainer{
  width: 100%;
}

.flomecolButtonHeight{
  min-height: 56px;
}

.tabs-root {
  flex-grow: 1;
  background-color: #336636;
}

.tabs-tabs-root {
  border-bottom: 1px solid #1d1d1d;
}

.tabs-indicator {
  background-color: #ffffff !important;
  height: 4px !important;
}

.tab-root {
  text-transform: initial;
  min-width: 72px !important;
  font-size: 18px !important;
}

.tab-root :hover{
  color: #ffffff;
  opacity: 5;
}

.tab-root :focus{
  color: #ffffff;
}

.viatics-check-icon{
  color:#28a745;
}

.viatics-close-icon{
  color:#dc3545;
}