.inputTextFieldContainerCash {
    padding: 5px;
    margin-top: 15px !important;
}

@media (max-width: 600px){
    .inputTextFieldContainerCash {
        padding: 5px 0px;
        margin-top: 0px !important;
    }
  }