#printAreaProductionControl {
    margin: 5px;
    width: 370px;
    margin: auto;
    margin-top: 1rem;
}

.printProductionControlContainer {
    border: dashed 2px;
    margin-bottom: 1rem;
    width: 370px;
    height: 140px;
}

.printProductionControl {
    padding: 10px;
    display: flex;
    flex-direction: row;
    width: 350px;
    height: 100px;
    align-items: center;
    justify-content: space-between;
}

.productionControlModalContainer {
    margin: auto;
}

.productionControlLeftPanel {
    font-size: 0.6rem;
}

.stickerLabel {
    text-align: center;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 0.8rem;
}

.printButton {
    width: 100%;
}