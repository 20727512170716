.accessTypeButtonContainer {
    color: #fff;
    background-color: #336636;
    display: grid;
    justify-content: center;
    padding: 2rem;
    border-radius: 1rem;
    text-align: center;
}

.accessTypeButtonContainerSelected {
    color: #fff;
    background-color: #254725;
    display: grid;
    justify-content: center;
    padding: 2rem;
    border-radius: 1rem;
    text-align: center;
}

.accessTypeButtonContainer:hover {
    background-color: #254725;
    cursor: pointer;
    font-weight: bold;
}

.accessTypeIcon {
    font-size: 5rem !important;
}

.accessTypeButtonName {
    display: grid;
    min-height: 3rem;
    align-items: center;
}

.accessTypesContainer {
    padding-top: 3rem;
}

.accessTypesTitle {
    padding: 1rem;
    text-align: center;
    font-size: 2rem;
    width: 100%;
}